import { FC, useState } from 'react'
import { SectionTechnicalSpecificationsProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import {
  Container,
  SectionWrapper,
  Modal,
  SizeGuide,
  Button,
} from '@aether/components'
import { AccordionTechSection } from './AccordionTechSection'
import { FeaturesAndFitTechSection } from './FeaturesAndFitTechSection'
import { TemperatureRangeTechSection } from './TemperatureRangeTechSection/TemperatureRangeTechSection'
import { useTranslation } from 'react-i18next'
import { getProductMetafieldValues } from '@aether/utils'
import { GTMOpenSizeGuide } from '@aether/services/gtm-service'
import { Close } from '@aether/icons'

const Root = styled('div', {
  padding: '$32 0 1px 0',
  '@md': {
    padding: '$72 0 1px 0',
  },
  variants: {
    isRenderedInModal: {
      true: {
        minWidth: '60vw',
        '@md': {
          padding: '$48 0 1px 0',
        },
      },
      false: {},
    },
  },
})

const TitleContainer = styled('div', {
  paddingTop: '$16',
  paddingBottom: '$28',
  borderTop: '1px solid',
  display: 'grid',
  gap: '$16',
  gridAutoFlow: 'row',
  justifyItems: 'start',
  gridTemplateColumns: 'auto 1fr min-content',
  '@lg': {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr min-content',
    alignItems: 'center',
    paddingTop: '$24',
    paddingBottom: '$48',
  },
})

const Title = styled('h2', {
  $aetherFont: 'heading02',
  '@lg': {
    $aetherFont: 'heading03',
  },
})

const StyledButton = styled(Button, {
  gridRow: '2/3',
  '@lg': {
    gridColumn: '2/3',
    gridRow: '1',
  },
})

const StyledCloseModal = styled('button', {
  gridColumn: '3/4',
  background: 'transparent',
  $focusStyle: 'default',
  padding: '$4',
})

export const SectionTechnicalSpecifications: FC<
  SectionTechnicalSpecificationsProps & {
    isRenderedInModal?: boolean
    handleModalClose?: () => void
  }
> = ({
  backgroundColor,
  sections,
  productId,
  shopifyData,
  siteConfiguration,
  // This Section can be used both in PageBuilder & as Modal under the SectionProductConversionArea
  isRenderedInModal = false,
  handleModalClose,
}) => {
  const [isSizeGuideOpen, setSizeGuideOpen] = useState(false)
  const isMd = useMediaQuery('md')

  const { t } = useTranslation('sectionTechnicalSpecifications')

  const productData =
    productId && shopifyData ? shopifyData.products[productId] : null

  const techFeatures = getProductMetafieldValues('techFeatures', productData)

  const technicalFeaturesMap = siteConfiguration?.technicalFeaturesMap

  const sizeGuide = siteConfiguration?.sizeGuides.find(
    (sizeGuide) => sizeGuide.shopifyId === productData?.sizeGuide?.value,
  )

  const closeSizeGuide = () => {
    setSizeGuideOpen(false)
  }

  const openSizeGuide = () => {
    setSizeGuideOpen(true)
    GTMOpenSizeGuide('SectionTechnicalSpecifications')
  }

  return (
    <SectionWrapper withBackground={Boolean(backgroundColor)}>
      <Root
        css={{
          backgroundColor: backgroundColor?.rgba || 'unset',
          minHeight: isRenderedInModal ? '100vh' : 'auto',
        }}
        id="sectionTechnicalSpecifications"
        isRenderedInModal={isRenderedInModal}
      >
        <Container>
          <TitleContainer>
            <Title>{t('title')}</Title>
            {sizeGuide && (
              <StyledButton onClick={openSizeGuide}>
                {t('sizeGuideButtonLabel')}
              </StyledButton>
            )}
            {isRenderedInModal && handleModalClose && (
              <StyledCloseModal
                onClick={handleModalClose}
                aria-label="Close Modal"
              >
                <Close />
              </StyledCloseModal>
            )}
          </TitleContainer>
          {sections?.map((section) => {
            switch (section?.type) {
              case 'accordionTechSection':
                return <AccordionTechSection {...section} key={section?.id} />
              case 'featuresAndFitTechSection':
                return (
                  <FeaturesAndFitTechSection
                    {...section}
                    key={section?.id}
                    technicalFeaturesMap={technicalFeaturesMap}
                    techFeatures={techFeatures}
                  />
                )
              case 'temperatureRangeTechSection':
                return (
                  <TemperatureRangeTechSection {...section} key={section?.id} />
                )
              default:
                return null
            }
          })}
        </Container>
      </Root>

      <Modal
        preventScroll={true}
        isOpen={isSizeGuideOpen}
        size={isMd ? 'intrinsic' : 'stretch'}
        position={isMd ? 'top100' : 'center'}
        onRequestClose={closeSizeGuide}
      >
        <SizeGuide closeSizeGuide={closeSizeGuide} sizeGuide={sizeGuide} />
      </Modal>
    </SectionWrapper>
  )
}
