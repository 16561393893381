import { FC, useEffect } from 'react'
import { SectionShopTheProductsProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, SectionWrapper } from '@aether/components'
import { MobileGallery } from './MobileGallery'
import { DesktopGallery } from './DesktopGallery'
import { GTMViewItemList } from '@aether/services/gtm-service'
import { useInView } from 'react-intersection-observer'

const Root = styled(Container, {
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
        paddingTop: '$56',
        paddingBottom: '$56',
        '@md': {
          paddingTop: '$72',
          paddingBottom: '$72',
        },
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
})

const StyledSectionTitle = styled('h3', {
  $aetherFont: 'heading03',
  paddingBottom: '$32',
  variants: {
    appearance: {
      journal: {
        paddingTop: '$28',
        borderTop: '1px solid $black',
      },
      default: {},
    },
  },
})

export const SectionShopTheProducts: FC<SectionShopTheProductsProps> = ({
  title,
  shopifyData,
  shopifyIds,
  pageContext,
}) => {
  const [rootRef, sectionInView] = useInView({
    triggerOnce: true,
  })

  const allProducts = shopifyData?.products || {}
  const collections = shopifyData?.collections || []
  const productIds = (shopifyIds || []).map((product) => product?.id)
  const products = productIds
    .map((id) => allProducts[id])
    .filter((p) => Boolean(p))

  const isLg = useMediaQuery('lg')

  useEffect(() => {
    if (sectionInView) {
      GTMViewItemList(products, 'section_shop_the_products')
    }
  }, [sectionInView])

  if (!products.length) return null

  return (
    <SectionWrapper withBackground={pageContext === 'journal'}>
      <Root
        spaceSize="none"
        appearance={pageContext}
        id={'sectionShopTheProducts'}
        ref={rootRef}
      >
        <Container>
          {title ? (
            <StyledSectionTitle appearance={pageContext}>
              {title}
            </StyledSectionTitle>
          ) : null}
        </Container>
        {isLg ? (
          <Container>
            <DesktopGallery products={products} collections={collections} />
          </Container>
        ) : (
          <MobileGallery products={products} collections={collections} />
        )}
      </Root>
    </SectionWrapper>
  )
}
