import { FC, PropsWithChildren } from 'react'
import { CSS, styled } from '@aether/styles'

type ChartProps = PropsWithChildren<{ height: number; width: number }>

type BarProps = PropsWithChildren<{
  height: number
  width: number
  fill?: CSS['fill']
  x: number
  y: number
  radius?: number
}>

type BarChartProps = PropsWithChildren<{
  data: number[]
  fill?: CSS['fill']
  scaleMin?: number
  scaleMax?: number
  scaleRatio?: number
  barWidth?: number
  barMargin?: number
  barRadius?: number
  formatLabel?: (
    value: number,
    index: number,
    lenght: number,
  ) => string | number
}>

const Label = styled('text', {
  $aetherFont: 'ui02',
})

const Rect = styled('rect', {
  transition: '0.3s',
})

const Chart: FC<ChartProps> = ({ children, height, width }) => (
  <svg
    style={{ overflow: 'visible' }}
    viewBox={`0 30 ${width} ${height}`}
    height={height}
    width={width}
  >
    {children}
  </svg>
)

const Bar: FC<BarProps> = ({
  fill = '$black',
  x,
  y,
  height,
  width,
  radius = 0,
}) => (
  <Rect css={{ fill }} x={x} y={y} height={height} width={width} rx={radius} />
)

const getGreatestValue = (values: number[]) =>
  values.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity)

const getDifference = (a: number, b: number) => {
  return Math.abs(a - b)
}

const getLabelsData = (
  width: number,
  min: number,
  max: number,
  scale: number,
): { x: number; value: number; scaleStep: number }[] => {
  const maxValue = getDifference(min, max)
  return [...Array(maxValue)].reduce(
    (acc) => {
      const prevScaleStep = acc[acc.length - 1].scaleStep
      const prevValue = acc[acc.length - 1].value

      const scaleStep = prevScaleStep + scale

      if (scaleStep <= maxValue) {
        const x = width * (scaleStep / maxValue)
        const value = prevValue + scale

        return [...acc, { x, scaleStep, value }]
      }

      return acc
    },
    [{ scaleStep: 0, value: min, x: 0 }],
  )
}

const BAR_WIDTH = 4
const BAR_MARGIN = 10
const BAR_RADIUS = 2
const LABLE_HEIGHT = 30

const SCALE_MIN = -20
const SCALE_MAX = 100
const SCALE_RATIO = 10

/**
 * hack - in order to center the
 * labels on the x axis we need the width of the coomponent.
 * We add here approx width of the usual label to  make them more or less centers
 *
 */
const LABELS_OFFSE = 37

export const BarChart: FC<BarChartProps> = ({
  data,
  fill = '$black',
  scaleMin = SCALE_MIN,
  scaleMax = SCALE_MAX,
  scaleRatio = SCALE_RATIO,
  barWidth = BAR_WIDTH,
  barMargin = BAR_MARGIN,
  barRadius = BAR_RADIUS,
  formatLabel,
}) => {
  const width = data.length * (barWidth + barMargin)
  const height = getGreatestValue(data) + LABLE_HEIGHT
  const labelsData = getLabelsData(
    width - LABELS_OFFSE,
    scaleMin,
    scaleMax,
    scaleRatio,
  )

  return (
    <div>
      <Chart height={height} width={width}>
        {data.map((value, index) => (
          <Bar
            key={index}
            fill={fill}
            x={index * (barWidth + barMargin)}
            y={height - value}
            width={barWidth}
            height={value}
            radius={barRadius}
          />
        ))}
        {labelsData.map(({ x, value }, index) => (
          <Label key={x} x={x} y={height + LABLE_HEIGHT} css={{ fill }}>
            {formatLabel ? formatLabel(value, index, labelsData.length) : value}
          </Label>
        ))}
      </Chart>
    </div>
  )
}
