'use client'
import { useCartContext } from '@aether/cart'
import {
  Button,
  Media,
  ProductPrice,
  ProductVariantPrice,
} from '@aether/components'
import { Discount } from '@aether/discounts/utils-context'
import {
  ProductColorType,
  ShopifyProduct,
  ShopifyProductVariant,
} from '@aether/models'
import { styled } from '@aether/styles'
import { formatProductName } from '@aether/utils'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useShowOnScroll } from './useShowOnScroll'
const StickyWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  margin: '0 auto',
  pointerEvents: 'none',
  width: 'fit-content',
  right: 0,
  left: 0,
  bottom: '$24',
  zIndex: '$stickyButton',
  transform: 'translateY(80px)',
  transition: 'transform 500ms ease-in-out',
  variants: {
    visible: {
      true: {
        transform: 'translateY(0)',
      },
    },
  },
})
const ButtonWrap = styled('div', {
  display: 'grid',
  color: 'white',
  pointerEvents: 'all',
  opacity: '0.85',
  width: 'fit-content',
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: '1',
  },
})

const ContentWrap = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridGap: '$16',
  $aetherFont: 'ui01',
  color: '$white',
  width: '100%',
  gridTemplateColumns: '18px auto 1fr',
  '@lg': {
    gridTemplateColumns: '18px auto 1fr 1fr',
  },
})

const Color = styled('div', {
  height: 22,
  width: 22,
  border: '$white solid 1px;',
  borderRadius: '$rMax',
  overflow: 'hidden',
})

const Text = styled('span', {
  gridColumn: '2 / 3',
  gridRow: '1',
  '@lg': {
    gridColumn: '4 / -1',
  },
})
const ProductName = styled('span', {
  gridColumn: '2/3',
  display: 'none',
  '@lg': {
    display: 'block',
  },
})
const AdditionalLabels = styled('span', {
  justifySelf: 'start',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$12',
  gridColumn: '3 / 4',
  '@lg': {
    gridColumn: '3 / 4',
  },
})

const PriceWrap = styled('div', {
  $aetherFont: 'ui01',
})

const ProductPriceWrapper = styled('span', {
  display: 'flex',
  flexDirection: 'row-reverse',
})

type StickyAddToCartButtonProps = {
  color?: ProductColorType
  variant?: ShopifyProductVariant
  productData?: ShopifyProduct
  onNoVariantSelected?: () => void
  onAddToCart: () => void
  availableForSale?: boolean
  preOrderIndicator?: boolean
  discount?: Discount
}

export const StickyAddToCartButton = ({
  color,
  variant,
  productData,
  onNoVariantSelected,
  onAddToCart,
  availableForSale = true,
  preOrderIndicator = false,
  discount,
}: StickyAddToCartButtonProps) => {
  const { t } = useTranslation('cart')
  const handleAddToCartClick = async () => {
    if (!variant) {
      onNoVariantSelected && onNoVariantSelected()
      return
    }
    onAddToCart()
  }
  const showAfterRef = useRef<HTMLElement>()
  const hideAfterRef = useRef<HTMLElement>()
  useEffect(() => {
    if (typeof window === 'undefined') return
    showAfterRef.current = document.querySelector(
      '#section-product-conversion-area-main',
    ) as HTMLElement
    hideAfterRef.current = document.querySelector('footer') as HTMLElement
  }, [])

  const isVisible = useShowOnScroll({
    showAfter: showAfterRef.current,
    hideAfter: hideAfterRef.current,
    showAfterOffsetType: 'bottomInView',
    showAfterOffset: 100,
    hideAfterOffsetType: 'topInView',
  })
  const cart = useCartContext()

  // used to hide the button after adding product to cart
  const isInCart = useMemo(() => {
    if (!cart?.cart) return false
    const res = cart.cart.lines.some(
      (item) => item.merchandise.id === variant?.id,
    )

    return res
  }, [cart.cart, variant?.id])

  if (!availableForSale) return null
  return (
    <StickyWrap visible={isVisible && !isInCart}>
      <ButtonWrap>
        <Button
          as="button"
          appearance="badgeBlack"
          onClick={handleAddToCartClick}
        >
          <ContentWrap>
            {color && (
              <Color>
                <Media
                  {...color.image}
                  hardcropRatio="square"
                  sizes={{ default: '22px' }}
                />
              </Color>
            )}
            <ProductName>
              {formatProductName(productData?.title ?? '')}
            </ProductName>
            <AdditionalLabels>
              {(variant || productData) && (
                <PriceWrap>
                  {variant ? (
                    <ProductVariantPrice
                      productVariant={variant}
                      discount={discount}
                      promoPriceColor="white"
                    />
                  ) : (
                    <ProductPriceWrapper>
                      <ProductPrice
                        product={productData}
                        discount={discount}
                        promoPriceColor="white"
                      />
                    </ProductPriceWrapper>
                  )}
                </PriceWrap>
              )}
            </AdditionalLabels>
            <Text>{preOrderIndicator ? t('preorder') : t('addToCart')}</Text>
          </ContentWrap>
        </Button>
      </ButtonWrap>
    </StickyWrap>
  )
}
