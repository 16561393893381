import { FC, useRef, useState } from 'react'
import { styled } from '@aether/styles'
import { TemperatureRangeTechSectionType } from '@aether/models'
import { BarChart } from './BarChart'
import { RadioSwitch } from '@aether/components'
import { useTemperatureChart } from './useTemperatureChart'

const Root = styled('div', {
  padding: '$8 0 $40 0',
  borderTop: '1px solid',
  display: 'grid',
  gridRowGap: '$16',
  '@md': {
    gridRowGap: '$16',
    padding: '$24 0 $48 0',
  },
})

const Title = styled('h3', {
  gridRow: 1,
  gridColumn: 1,
  $aetherFont: 'heading06Caps',
  alignSelf: 'center',
})

const TempRangeText = styled('span', {
  gridRow: 2,
  $aetherFont: 'ui02',
  justifySelf: 'start',
  alignSelf: 'center',
})

const RadioSwitchWrap = styled('div', {
  gridRow: 2,
  gridColumn: '2/2',
  justifyContent: 'end',
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'end',
  width: '100%',
  '@md': {
    gridRow: 1,
  },
})

const ChartWrap = styled('div', {
  gridRow: 3,
  gridColumn: '1/3',
})

type Props = TemperatureRangeTechSectionType & {
  className?: string
}

export const TemperatureRangeTechSection: FC<Props> = ({
  className,
  minTemp,
  maxTemp,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [unit, setUnit] = useState<'C' | 'F'>('F')
  const unitSymbol = unit === 'C' ? '°C' : '°F'

  const { minTempConverted, maxTempConverted, ...chartProps } =
    useTemperatureChart({ chartRef, minTemp, maxTemp, unit })

  const formatLabel = (value: number, index: number, length: number) =>
    (index === 0 ? '' : index === length - 1 ? '' : '') +
    `${value}${unitSymbol}`

  return (
    <Root className={className} ref={chartRef}>
      <Title>Temperature Range</Title>
      <TempRangeText>
        Best used: {minTempConverted.toFixed(1)} {unitSymbol} -{' '}
        {maxTempConverted.toFixed(1)} {unitSymbol}
      </TempRangeText>
      <RadioSwitchWrap>
        <RadioSwitch
          items={[
            { label: '°F ', value: 'F' },
            { label: '°C ', value: 'C' },
          ]}
          value={unit}
          onChange={(newValue: string) => {
            if (newValue === 'F' || newValue === 'C') {
              setUnit(newValue)
            }
          }}
        />
      </RadioSwitchWrap>
      <ChartWrap>
        <BarChart {...chartProps} formatLabel={formatLabel} />
      </ChartWrap>
    </Root>
  )
}
