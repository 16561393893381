import { FC } from 'react'
import dynamic from 'next/dynamic'
import { PageBuilderSection, ShopifyProductsData } from '@aether/models'

import { SectionEditorialRichText } from '@aether/section-editorial-rich-text'
import {
  SectionProductConversionArea,
  SectionCollectionHero,
  SectionHero,
  SectionJournalHero,
  SectionJournalHeader,
  SectionDoubleMedia,
} from '@aether/sections'

const DynamicSectionCollectionItems = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionCollectionItems),
)

const DynamicSectionPortraitPictureWithCaption = dynamic(() =>
  import('@aether/sections').then(
    (mod) => mod.SectionPortraitPictureWithCaption,
  ),
)

const DynamicSectionLandscapePictureWithCaption = dynamic(() =>
  import('@aether/sections').then(
    (mod) => mod.SectionLandscapePictureWithCaption,
  ),
)

const DynamicSectionPictureWithCaption = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionPictureWithCaption),
)

const DynamicSectionTripleCards = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionTripleCards),
)

const DynamicSectionDoubleCardsWithCaption = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionDoubleCardsWithCaption),
)

const DynamicSectionPostPreview = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionPostPreview),
)

const DynamicSectionSingleImage = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionSingleImage),
)

const DynamicSectionHalfImage = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionHalfImage),
)

const DynamicSectionDoubleImage = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionDoubleImage),
)

const DynamicSectionCategoryPostsPreview = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionCategoryPostsPreview),
)

const DynamicSectionShopTheProducts = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionShopTheProducts),
)

const DynamicSectionScatteredGallery = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionScatteredGallery),
)

const DynamicSectionCollectionFiltering = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionCollectionFiltering),
)

const DynamicSectionProgressIndicator = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionProgressIndicator),
)

const DynamicSectionStoresLandingPage = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionStoresLandingPage),
)

const DynamicSectionStoreDetails = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionStoreDetails),
)

const DynamicSectionQuote = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionQuote),
)

const DynamicSectionTripleImage = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionTripleImage),
)

const DynamicSectionAccordionGroups = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionAccordionGroups),
)

const DynamicSectionPageTitle = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionPageTitle),
)

const DynamicSectionAbout = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionAbout),
)

const DynamicSectionBoldText = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionBoldText),
)

const DynamicSectionCollectionAnalytics = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionCollectionAnalytics),
)
const DynamicSectionRichText = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionRichText),
)

const DynamicSectionSinglePromo = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionSinglePromo),
)

const DynamicSectionSingleImageWithDoubleText = dynamic(() =>
  import('@aether/sections').then(
    (mod) => mod.SectionSingleImageWithDoubleText,
  ),
)

const DynamicSectionImagesWithDescription = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionImagesWithDescription),
)

const DynamicSectionDoublePromo = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionDoublePromo),
)

const DynamicSectionHeroLanding = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionHeroLanding),
)

const DynamicSectionTechnicalSpecifications = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionTechnicalSpecifications),
)

const DynamicSectionStackImages = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionStackImages),
)

const DynamicSectionProductGuarantees = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionProductGuarantees),
)

const DynamicSectionVideoPlayer = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionVideoPlayer),
)

const DynamicSectionKlaviyoFormEmbed = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionKlaviyoFormEmbed),
)

const DynamicSectionCatalog = dynamic(() =>
  import('@aether/sections').then((mod) => mod.SectionCatalog),
)

type PageBuilderProps = {
  section: PageBuilderSection
  shopifyData?: ShopifyProductsData
}

export const PageBuilder: FC<PageBuilderProps> = ({ section, shopifyData }) => {
  /**
   * We use two section import strategies:
   * - Dynamic - in order to minimise the size of bundle chunks, we prefer to import sections dynamically
   * - Static - to prevent the "blink" effect in most exposed sections we use static imports
   */
  switch (section._type) {
    // dynamic sections ======================================
    case 'sectionCatalog':
      return <DynamicSectionCatalog {...section} />

    case 'sectionKlaviyoFormEmbed':
      return <DynamicSectionKlaviyoFormEmbed {...section} />

    case 'sectionVideoPlayer':
      return <DynamicSectionVideoPlayer {...section} />

    case 'sectionProductGuarantees':
      return <DynamicSectionProductGuarantees {...section} />

    case 'sectionStackImages':
      return <DynamicSectionStackImages {...section} />

    case 'sectionTechnicalSpecifications':
      return (
        <DynamicSectionTechnicalSpecifications
          {...section}
          shopifyData={shopifyData}
        />
      )

    case 'sectionHeroLanding':
      return <DynamicSectionHeroLanding {...section} />

    case 'sectionDoublePromo':
      return <DynamicSectionDoublePromo {...section} />

    case 'sectionImagesWithDescription':
      return <DynamicSectionImagesWithDescription {...section} />

    case 'sectionSinglePromo':
      return <DynamicSectionSinglePromo {...section} />

    case 'sectionPageTitle':
      return <DynamicSectionPageTitle {...section} />

    case 'sectionAccordionGroups':
      return <DynamicSectionAccordionGroups {...section} />

    case 'sectionAbout':
      return <DynamicSectionAbout {...section} />

    case 'sectionBoldText':
      return <DynamicSectionBoldText {...section} />

    case 'sectionCollectionAnalytics':
      return (
        <DynamicSectionCollectionAnalytics
          {...section}
          shopifyData={shopifyData}
        />
      )

    case 'sectionSingleImageWithDoubleText':
      return <DynamicSectionSingleImageWithDoubleText {...section} />

    case 'sectionTripleImage':
      return <DynamicSectionTripleImage {...section} />

    case 'sectionQuote':
      return <DynamicSectionQuote {...section} />

    case 'sectionStoreDetails':
      return <DynamicSectionStoreDetails {...section} />

    case 'sectionStoresLandingPage':
      return <DynamicSectionStoresLandingPage {...section} />

    case 'sectionProgressIndicator':
      return <DynamicSectionProgressIndicator {...section} />
    case 'sectionCollectionFiltering':
      return (
        <DynamicSectionCollectionFiltering
          {...section}
          shopifyData={shopifyData}
        />
      )

    case 'sectionScatteredGallery':
      return <DynamicSectionScatteredGallery {...section} />

    case 'sectionDoubleImage':
      return <DynamicSectionDoubleImage {...section} />

    case 'sectionShopTheProducts':
      return (
        <DynamicSectionShopTheProducts {...section} shopifyData={shopifyData} />
      )

    case 'sectionCategoryPostsPreview':
      return <DynamicSectionCategoryPostsPreview {...section} />

    case 'sectionHalfImage':
      return <DynamicSectionHalfImage {...section} />

    case 'sectionSingleImage':
      return <DynamicSectionSingleImage {...section} />

    case 'sectionPostPreview':
      return <DynamicSectionPostPreview {...section} />

    case 'sectionPictureWithCaption':
      return <DynamicSectionPictureWithCaption {...section} />

    case 'sectionLandscapePictureWithCaption':
      return <DynamicSectionLandscapePictureWithCaption {...section} />

    case 'sectionPortraitPictureWithCaption':
      return <DynamicSectionPortraitPictureWithCaption {...section} />

    case 'sectionTripleCards':
      return <DynamicSectionTripleCards {...section} />

    case 'sectionDoubleCardsWithCaption':
      return <DynamicSectionDoubleCardsWithCaption {...section} />

    case 'sectionCollectionItems':
      return (
        <DynamicSectionCollectionItems {...section} shopifyData={shopifyData} />
      )
    case 'sectionRichText':
      return <DynamicSectionRichText {...section} />

    // static sections ======================================
    case 'sectionDoubleMedia':
      return <SectionDoubleMedia {...section} />

    case 'sectionJournalHeader':
      return <SectionJournalHeader {...section} />

    case 'sectionJournalHero':
      return <SectionJournalHero {...section} />

    case 'sectionEditorialRichText':
      return <SectionEditorialRichText {...section} />

    case 'sectionHero':
      return <SectionHero {...section} />

    case 'sectionCollectionHero':
      return <SectionCollectionHero {...section} shopifyData={shopifyData} />

    case 'sectionProductConversionArea':
      return (
        <SectionProductConversionArea {...section} shopifyData={shopifyData} />
      )
    case 'sectionShopifyRelatedProducts':
      return (
        <DynamicSectionShopTheProducts
          {...section}
          _type="sectionShopTheProducts"
          shopifyData={shopifyData}
        />
      )
    case 'sectionShopifyComplimentaryProducts':
      return (
        <DynamicSectionShopTheProducts
          {...section}
          _type="sectionShopTheProducts"
          shopifyData={shopifyData}
        />
      )
    default:
      return null
  }
}
