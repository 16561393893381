import { ShopifyProduct, ShopifyProductsData } from '@aether/models'
import { ProductOptionProps } from './ProductOption/ProductOptionGroup'
import { useEffect, useState } from 'react'
import { captureException } from '@sentry/nextjs'

type SelectedOptions = {
  name: string
  value: string
}[]

type ProductPageType = 'SINGLE_VARIANT' | 'MULTIPLE_OPTIONS' | undefined

export const useComputedProductData = (
  productData?: ShopifyProduct,
  shopifyData?: ShopifyProductsData,
) => {
  const { options: productOptions = [], variantsIds: variantIds = [] } =
    productData || {}
  const { variants: productVariants = {} } = shopifyData || {}
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>([])
  const currentProductVariants = Object.values(productVariants).filter(
    ({ id, availableForSale }) => variantIds.includes(id) && availableForSale,
  )

  const findOptionHierarchyIndex = (optionToSearch: { name: string }) =>
    productOptions.findIndex(({ name }) => name === optionToSearch.name)

  // reset selections on product change
  useEffect(() => {
    setSelectedOptions([])
  }, [productData?.id])

  const pageType: ProductPageType = (() => {
    if (variantIds.length === 1) return 'SINGLE_VARIANT'
    if (productOptions.length >= 1) return 'MULTIPLE_OPTIONS'
    captureException('Wrong ProductPageType')
    return undefined
  })()

  const onOptionChange = (value: string, name: string) => {
    const newOption = { name, value }
    const selectedOptionIndex = findOptionHierarchyIndex({ name })
    setSelectedOptions((prevState) => {
      // cimply add new option if non is selected
      if (!prevState.length) return [newOption]

      return [
        ...prevState.filter(
          (option, index) =>
            // unselect any option higher or in the same group
            option.name !== name && index < selectedOptionIndex,
        ),
        newOption,
      ]
    })
  }

  const currentVariant =
    (() => {
      if (pageType === 'SINGLE_VARIANT' || !pageType) {
        return productVariants[variantIds[0]]
      }
      if (
        pageType === 'MULTIPLE_OPTIONS' &&
        productOptions.length === selectedOptions?.length
      ) {
        //find variant that has same selectedOptions as optionsInQuery
        const variant = variantIds
          .map((id) => productVariants[id])
          .find(
            (variant) =>
              variant.availableForSale &&
              variant.selectedOptions.every(
                (option) =>
                  selectedOptions?.find((o) => o.name === option.name)
                    ?.value === option.value,
              ),
          )
        if (!variant) {
          captureException(new Error('Variant with selected options not found'))
          return undefined
        }

        return variant
      }
      return undefined
    })() || undefined

  const optionsToSelect: Pick<ProductOptionProps, 'name' | 'id' | 'values'>[] =
    (() => {
      if (pageType === 'MULTIPLE_OPTIONS') {
        return productOptions.map((option) => {
          return {
            id: option.name,
            name: option.name,
            values: option.values.map((value) => {
              // if any of selected option has same value as that option it is selected
              const selected = selectedOptions?.some(
                (selectedOption) =>
                  selectedOption.name === option.name &&
                  selectedOption.value === value,
              )

              const currentOptionIndex = findOptionHierarchyIndex(option)

              // Disable option if previous option is not yet selected
              const isHierarchicallyDisabled = (() => {
                const selectedOptionsIndexes = selectedOptions.map(
                  findOptionHierarchyIndex,
                )

                const isOneLowerOptionSelected =
                  selectedOptionsIndexes.includes(currentOptionIndex - 1)

                if (currentOptionIndex === 0) {
                  return false
                }

                if (isOneLowerOptionSelected) {
                  return false
                }

                return true
              })()

              // find if there is a variant that matches all lower hierarchy selected options and the current option
              const isAvailable = (() => {
                const lowerCherarchySelecteOptions = selectedOptions.filter(
                  (o) => findOptionHierarchyIndex(o) < currentOptionIndex,
                )

                const optionsToMatch: SelectedOptions = [
                  ...lowerCherarchySelecteOptions,
                  { name: option.name, value },
                ]

                // for the first hierarchy we only check if there is any available option in stack
                if (currentOptionIndex === 0) {
                  return currentProductVariants.find(
                    (variant) =>
                      variant.availableForSale &&
                      variant.selectedOptions.some(
                        (o) => o.name === option.name && o.value === value,
                      ),
                  )
                }

                const variantToMatch = currentProductVariants.find(
                  ({
                    selectedOptions: availableOptionCollection,
                    availableForSale,
                  }) =>
                    availableForSale &&
                    optionsToMatch.every((optionToMatch) =>
                      availableOptionCollection.find(
                        (o) =>
                          o.name === optionToMatch.name &&
                          o.value === optionToMatch.value,
                      ),
                    ),
                )

                return !!variantToMatch
              })()

              return {
                optionId: option.name,
                id: value,
                title: value,
                selected,
                disabled: isHierarchicallyDisabled || !isAvailable,
              }
            }),
          }
        })
      }
      return []
    })()

  return [currentVariant, optionsToSelect, onOptionChange] as const
}
