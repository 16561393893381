import { FC } from 'react'
import { SectionImagesWithDescriptionProps } from '@aether/models'
import { Container, Link, MediaLink, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const TitleWrap = styled('div', {
  display: 'flex',
  margin: '0 0 $24 0',
  '@lg': {
    margin: 0,
    paddingBottom: '$32',
  },

  variants: {
    border: {
      top: {
        borderTop: '1px solid $black',
        paddingTop: '$24',
      },
      none: {
        border: 'none',
      },
    },
  },
})

const StyledTitle = styled('h3', {
  $aetherFont: 'heading03',
  color: '$black',
})

const Card = styled('article', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  gap: '$20',
})

const CardsList = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '$48',
  gridAutoColumns: '1fr',

  '@lg': {
    columnGap: '$20',
  },

  variants: {
    wrapCards: {
      false: {
        '@lg': {
          gridAutoFlow: 'column',
        },
      },
      true: {
        '@lg': {
          gridTemplateColumns: '1fr 1fr',
        },
        '@xl': {
          gridAutoFlow: 'column',
        },
      },
    },
  },
})

const StyledText = styled('p', {
  $aetherFont: 'body02',
  color: '$black',
})

const StyledLink = styled(Link, {
  display: 'flex',
  width: 'min-content',
})

const CardListItem = styled('li', {
  overflow: 'hidden',
})

const CardTitleContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$16',
})

const CardTitle = styled('h4', {
  $aetherFont: 'heading04',
  color: '$black',
})

export const SectionImagesWithDescription: FC<
  SectionImagesWithDescriptionProps
> = ({
  title,
  borderPosition,
  cards,
  sectionSpaceSize,
  priority,
  sectionColor,
}) => {
  return (
    <SectionWrapper withBackground={sectionColor && sectionColor !== 'default'}>
      <Container spaceSize={sectionSpaceSize} sectionColor={sectionColor}>
        {title && (
          <TitleWrap border={borderPosition}>
            <StyledTitle>{title}</StyledTitle>
          </TitleWrap>
        )}
        <CardsList wrapCards={cards && cards?.length > 3}>
          {cards &&
            cards.map((card, index) => (
              <CardListItem key={`${index}-${card.text}`}>
                <Card>
                  {card.image && (
                    <MediaLink
                      media={{
                        ...card.image,
                        hardcropRatio: 'portrait1',
                        sizes: { lg: '50vw' },
                        priority,
                      }}
                      noIndex={false}
                      link={
                        card.ctaLabel && card.ctaLink ? card.ctaLink : undefined
                      }
                    />
                  )}
                  <CardTitleContainer>
                    {card.title && <CardTitle>{card.title}</CardTitle>}
                    {card.text && <StyledText>{card.text}</StyledText>}
                    {card.ctaLink && card.ctaLabel && (
                      <StyledLink {...card.ctaLink} appearance="primary">
                        {card.ctaLabel}
                      </StyledLink>
                    )}
                  </CardTitleContainer>
                </Card>
              </CardListItem>
            ))}
        </CardsList>
      </Container>
    </SectionWrapper>
  )
}
