import { FC, Fragment } from 'react'
import { styled } from '@aether/styles'
import {
  FeaturesAndFitTechSectionType,
  ProductTemperatureType,
} from '@aether/models'
import { Media } from '@aether/components'

const Root = styled('div', {
  paddingBottom: '$40',
  width: '100%',
})

type Props = FeaturesAndFitTechSectionType & {
  className?: string
  technicalFeaturesMap: ProductTemperatureType[] | undefined
  techFeatures?: string[]
}

const FeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',
  '@md': {
    width: 50,
    height: 50,
  },
})

const FeaturesContent = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 100px)',
  gap: '$24',
  paddingTop: '$28',
})

const Feature = styled('div', {
  width: 100,
  $aetherFont: 'ui03',
  display: 'flex',
  gap: '$12',
  flexDirection: 'column',
  justifyContent: 'left',
  textAlign: 'center',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

const FeaturesTitle = styled('h3', {
  $aetherFont: 'heading06Caps',
})

export const FeaturesAndFitTechSection: FC<Props> = ({
  className,
  technicalFeaturesMap,
  techFeatures,
}) => {
  return (
    <Root className={className}>
      {techFeatures && technicalFeaturesMap && (
        <>
          <FeaturesTitle>Features & FIT</FeaturesTitle>
          <FeaturesContent>
            {techFeatures.map((feature, index) => (
              <Fragment key={`${feature}-${index}`}>
                {technicalFeaturesMap.map((feat) => {
                  if (feat.shopifyId !== feature) return null

                  return (
                    <Feature key={feat.label}>
                      <FeatureImage>
                        <Media
                          {...feat.image}
                          hardcropRatio="square"
                          sizes={{ default: '42px', md: '50px' }}
                        />
                      </FeatureImage>
                      {feat.label}
                    </Feature>
                  )
                })}
              </Fragment>
            ))}
          </FeaturesContent>
        </>
      )}
    </Root>
  )
}
