import { FC, PropsWithChildren } from 'react'
import { PageLink, SectionSinglePromoProps } from '@aether/models'
import { screenSizes, styled } from '@aether/styles'
import { Container, Link, SectionWrapper, Media } from '@aether/components'

const Wrap = styled('div', {
  display: 'grid',
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})
const ContainerWrap = styled('div', {
  position: 'relative',
  zIndex: '$content',
  display: 'grid',
  padding: '$24 0',
  gridColum: 1,
  gridRow: 2,
  pointerEvents: 'none',
  '@lg': {
    padding: '$48 0',
    gridColumn: 1,
    gridRow: 1,
  },
})
const ContentWrap = styled('div', {
  height: '100%',
  display: 'flex',

  variants: {
    leftMarginSpace: {
      large: {
        '@lg': {
          $lin: {
            marginLeft: ['6px', '$64'],
            marginRight: ['6px', '$64'],
          },
        },
      },
      medium: {
        '@lg': {
          $lin: {
            marginLeft: ['6px', '$56'],
            marginRight: ['6px', '$56'],
          },
        },
      },
      small: {
        '@lg': {
          $lin: {
            marginLeft: ['6px', '$40'],
            marginRight: ['6px', '$40'],
          },
        },
      },
    },
    textPosition: {
      top: {
        alignItems: 'baseline',
      },
      center: {
        alignItems: 'center',
      },
      bottom: {
        alignItems: 'end',
      },
    },
    color: {
      dark: {
        color: '$black',
      },
      light: {
        '@lg': {
          color: '$white',
        },
      },
    },
    borderPosition: {
      top: {
        borderTop: '1px solid',
        paddingTop: '$24',
      },
      left: {
        borderLeft: '1px solid',
        paddingLeft: '$12',
        '@lg': {
          paddingLeft: '$24',
        },
      },
      none: {
        border: 'none',
      },
    },
    isTextSticky: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      textPosition: 'bottom',
      isTextSticky: true,
      css: {
        position: 'sticky',
        top: '70%',
        height: 'auto',
      },
    },
  ],
})

const ContentContainer = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  gap: '$12',
  zIndex: '$content',
  alignContent: 'start',
  variants: {
    color: {
      dark: {
        color: '$black',
      },
      light: {
        '@lg': {
          color: '$white',
        },
      },
    },
  },
})
const TitleWrap = styled('h2', {
  $aetherFont: 'heading02',
  maxWidth: 600,
})
const Description = styled('p', {
  $aetherFont: 'body02',
  maxWidth: 400,
})

const SpacingContainer = styled('div', {
  variants: {
    withSpacing: {
      true: {
        $containerSpace: 'small',
        '@lg': {
          $containerSpace: 'none',
        },
      },
      false: {},
    },
  },
})

const StyledLink = styled(Link, {
  pointerEvents: 'all',
})

// custom contextual buttons styling as this component exceptionally places buttons on different background depending on breakpoint
const customButtonStyles = {
  [`@media (max-width: ${screenSizes.lg - 1}px)`]: {
    color: '$black',
    backgroundColor: 'transparent',
    borderColor: '$black',
  },
}

const OptionalLinkWrap: FC<PropsWithChildren<{ link?: PageLink }>> = ({
  link,
  children,
}) => {
  if (link) {
    return (
      <Link {...link} appearance="block" tabIndex={-1}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

// TODO: move to utilities
function isValidLink(link?: PageLink) {
  return Boolean(link?.action) || Boolean(link?.href)
}

export const SectionSinglePromo: FC<SectionSinglePromoProps> = ({
  title,
  image,
  description,
  ctaLabel,
  ctaLink,
  ctaAppearance,
  borderPosition,
  textColor,
  sectionSpaceSize,
  textPosition = 'top',
  isTextSticky,
  priority,
  secondaryCtaLabel,
  secondaryCtaLink,
  secondaryCtaAppearance,
}) => {
  const isFirstLinkPresent = Boolean(ctaLabel) && isValidLink(ctaLink)
  const isSecondaryLinkPresent =
    isValidLink(secondaryCtaLink) && Boolean(secondaryCtaLabel)
  const isMediaLinkable = isFirstLinkPresent && !isSecondaryLinkPresent

  return (
    <SectionWrapper
      withBackground={!sectionSpaceSize || sectionSpaceSize === 'none'}
    >
      <Container spaceSize={sectionSpaceSize}>
        <Wrap>
          <MediaWrap>
            <OptionalLinkWrap link={isMediaLinkable ? ctaLink : undefined}>
              <Media
                {...image}
                priority={priority}
                sizes={{ default: '100vw' }}
                withBackground
              />
            </OptionalLinkWrap>
          </MediaWrap>

          <ContainerWrap>
            <SpacingContainer
              withSpacing={!sectionSpaceSize || sectionSpaceSize === 'none'}
            >
              <ContentWrap
                color={textColor}
                borderPosition={borderPosition}
                textPosition={textPosition}
                isTextSticky={isTextSticky}
                leftMarginSpace={(() => {
                  switch (sectionSpaceSize) {
                    case 'small':
                    case 'none':
                      return 'large'
                    case 'medium':
                    case 'large':
                      return 'medium'
                    default:
                      return 'small'
                  }
                })()}
              >
                <ContentContainer>
                  {title && (
                    <TitleWrap as={priority ? 'h1' : 'h2'}>{title}</TitleWrap>
                  )}
                  {description && <Description>{description}</Description>}
                  {ctaLabel && ctaLink && (
                    <StyledLink
                      {...ctaLink}
                      appearance={ctaAppearance || 'primaryLightWhite'}
                      size={'M'}
                      css={customButtonStyles}
                    >
                      {ctaLabel}
                    </StyledLink>
                  )}
                  {secondaryCtaLabel && secondaryCtaLink && (
                    <StyledLink
                      {...secondaryCtaLink}
                      appearance={secondaryCtaAppearance || 'primaryLightWhite'}
                      size={'M'}
                      css={customButtonStyles}
                    >
                      {secondaryCtaLabel}
                    </StyledLink>
                  )}
                </ContentContainer>
              </ContentWrap>
            </SpacingContainer>
          </ContainerWrap>
        </Wrap>
      </Container>
    </SectionWrapper>
  )
}
