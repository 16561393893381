import { FC, useEffect, useRef, useState } from 'react'
import {
  MediaFieldType,
  SectionProductConversionAreaProps,
  ShopifyProduct,
} from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Button, Media, Modal, SectionWrapper } from '@aether/components'
import { useWunderkind } from '@aether/services/wunderkind-service'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { GTMViewItem } from '@aether/services/gtm-service'
import { getProductMetafieldValues } from '@aether/utils'
import { ProductGalleryDesktop } from './ProductGalleryDesktop'
import { ProductGalleryMobile } from './ProductGalleryMobile'
import { ProductDetails } from './ProductDetails'
import { ProductSoldOut } from './ProductSoldOut'
import { SectionTechnicalSpecifications } from '../SectionTechnicalSpecifications/SectionTechnicalSpecifications'
import { useUrlFragment } from '@aether/utils'

const Wrap = styled('div', {
  display: 'grid',
  gridGap: '$24',
  gridTemplateColumns: '1fr',
  $containerSpace: 'none',
  $containerSize: 'XL',
  '@lg': {
    $containerSpace: 'small',
    gridGap: '$40',
    gridTemplateColumns: '4fr 3fr',
  },
})

const TechnicalFeatures = styled('div', {
  paddingTop: '$16',
  display: 'grid',
})
const FeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',
})
const FeaturesTitle = styled('div', {
  $aetherFont: 'heading05',
  $containerSpace: 'small',
  '@lg': {
    $containerSpace: 'none',
  },
})
const FeaturesWrapSlider = styled('div', {
  marginTop: '$20',
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  width: '100%',
  '@lg': {
    overflow: 'hidden',
  },
})
const FeaturesContent = styled('div', {
  $containerSpace: 'small',
  display: 'grid',
  gridAutoColumns: '100px',
  gridAutoFlow: 'column',
  gridGap: '$8',
  '@lg': {
    width: '100%',
    padding: 0,
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    gridAutoFlow: 'unset',
    gridAutoColumns: 'unset',
    rowGap: '$20',
    columnGap: '$12',
  },
})

const Feature = styled('div', {
  width: 100,
  $aetherFont: 'ui03',
  justifySelf: 'center',
  display: 'flex',
  ':not(:last-child)': {
    marginBottom: '$12',
  },
  flexDirection: 'column',
  textAlign: 'center',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

export const DividerWrap = styled('div', {
  $containerSpace: 'small',
  '@lg': {
    $containerSpace: 'none',
  },
})

export const Divider = styled('div', { height: 1, background: '$black' })

const SectionWrapperNoTopMargin = styled(SectionWrapper, {
  marginTop: 0,
})

const StyledProductGalleryDesktop = styled(ProductGalleryDesktop, {
  display: 'none',
  '@lg': {
    display: 'grid',
  },
})

const StyledProductGalleryMobile = styled(ProductGalleryMobile, {
  display: 'grid',
  '@lg': {
    display: 'none',
  },
})

const StyledButton = styled(Button, {
  $aetherFont: 'ui03',
  padding: '0',
})

const TechnicalFeaturesHeadingWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
})

const TechnicalFeaturesDesktopLinkWrap = styled('div', {
  display: 'none',
  '@lg': {
    display: 'block',
  },
})

const TechnicalFeaturesMobileLinkWrap = styled('div', {
  display: 'flex',
  $containerSpace: 'small',
  marginTop: '$20',

  '@lg': {
    display: 'none',
  },
})

const TechnicalSpecificationsWrap = styled('div', {
  position: 'relative',
})

const MODAL_FRAGMENT = 'technical-specification'

type TechnicalFeaturesType = {
  label: string
  image: MediaFieldType
}[]

export const SectionProductConversionArea: FC<
  SectionProductConversionAreaProps
> = ({
  productId: initialProductId,
  sectionTechnicalSpecification,
  shopifyData,
  siteConfiguration,
  priority,
  isTechnicalSpecificationPresent,
}) => {
  const technicalFeaturesMap = siteConfiguration?.technicalFeaturesMap || []
  const router = useRouter()
  const [urlFragment, setUrlFragment] = useUrlFragment()
  const matchesMd = useMediaQuery('md')
  const currentHandle = Array.isArray(router.query.slug)
    ? router.query.slug[0]
    : router.query.slug

  const productId =
    Object.values(shopifyData?.products || {}).find((product) => {
      return product.handle === currentHandle
    })?.id || initialProductId

  const productData: ShopifyProduct | undefined =
    shopifyData?.products[productId]

  useWunderkind({
    pageType: 'product',
    productData,
  })

  const isSoldOut =
    productData?.soldOut && productData?.soldOut?.value === 'true'

  const images = productData?.images || []
  const { t } = useTranslation('product')

  const switchImage = (index: number) => {
    setActiveProductMediaId(index)
  }

  const stickyButtonTopRef = useRef<HTMLDivElement>(null)

  const mappedFeatures = (() => {
    if (!productData?.techFeatures?.value || !technicalFeaturesMap) return []
    const productTechFeatures: string[] = getProductMetafieldValues(
      'techFeatures',
      productData,
    )

    return productTechFeatures.reduce<TechnicalFeaturesType>(
      (acc, productFeature) => {
        const sanityFeature = technicalFeaturesMap.find(
          (sanityFeature) => sanityFeature.shopifyId === productFeature,
        )
        return sanityFeature
          ? [
              ...acc,
              {
                label: sanityFeature?.label,
                image: sanityFeature?.image,
              },
            ]
          : acc
      },
      [],
    )
  })()

  useEffect(() => {
    if (productData) {
      const productFirstVariant =
        productData.variantsIds && productData.variantsIds[0]
      if (productFirstVariant) {
        GTMViewItem(productData, shopifyData?.variants[productFirstVariant])
      }
    }
  }, [productData?.id, JSON.stringify(shopifyData?.variants)])

  const [activeProductMediaId, setActiveProductMediaId] = useState(0)

  useEffect(() => {
    setActiveProductMediaId(0)
  }, [productData?.id])
  return (
    <SectionWrapperNoTopMargin>
      <Wrap id="section-product-conversion-area-main">
        <StyledProductGalleryDesktop
          images={images}
          priority={priority}
          activeImageId={activeProductMediaId}
          setActiveImage={switchImage}
        />
        <StyledProductGalleryMobile
          images={images}
          priority={priority}
          activeImageId={activeProductMediaId}
          setActiveImage={switchImage}
        />
        <div ref={stickyButtonTopRef}>
          {isSoldOut ? (
            <ProductSoldOut
              productData={productData}
              shopifyData={shopifyData}
            />
          ) : (
            <ProductDetails
              siteConfiguration={siteConfiguration}
              productData={productData}
              shopifyData={shopifyData}
              productId={productId}
            />
          )}
          <DividerWrap>
            <Divider />
          </DividerWrap>
          {mappedFeatures.length > 0 && (
            <TechnicalFeatures>
              <TechnicalFeaturesHeadingWrap>
                <FeaturesTitle>{t('techFeatures')}</FeaturesTitle>
                {!!isTechnicalSpecificationPresent && (
                  <TechnicalFeaturesDesktopLinkWrap>
                    <StyledButton
                      appearance="badgeOutline"
                      onClick={() => setUrlFragment(MODAL_FRAGMENT)}
                    >
                      {t('scrollToTechnicalSpecification')}
                    </StyledButton>
                  </TechnicalFeaturesDesktopLinkWrap>
                )}
              </TechnicalFeaturesHeadingWrap>
              <FeaturesWrapSlider>
                <FeaturesContent>
                  {mappedFeatures.map((feature) => (
                    <Feature key={feature.label}>
                      <FeatureImage>
                        <Media
                          {...feature.image}
                          hardcropRatio="square"
                          sizes={{ default: '42px' }}
                        />
                      </FeatureImage>
                      <span>{feature.label}</span>
                    </Feature>
                  ))}
                </FeaturesContent>
              </FeaturesWrapSlider>

              {!!isTechnicalSpecificationPresent && (
                <TechnicalFeaturesMobileLinkWrap>
                  <Button
                    appearance="badgeOutline"
                    onClick={() => setUrlFragment(MODAL_FRAGMENT)}
                  >
                    {t('scrollToTechnicalSpecification')}
                  </Button>
                </TechnicalFeaturesMobileLinkWrap>
              )}
            </TechnicalFeatures>
          )}
        </div>
      </Wrap>
      {!!isTechnicalSpecificationPresent && sectionTechnicalSpecification && (
        <Modal
          preventScroll={true}
          isOpen={urlFragment === MODAL_FRAGMENT}
          size={matchesMd ? 'intrinsic' : 'stretch'}
          position={matchesMd ? 'right' : 'center'}
          onRequestClose={() => setUrlFragment('')}
          transition={'slide-from-right'}
          overlayVariant={'darker'}
        >
          <TechnicalSpecificationsWrap>
            <SectionTechnicalSpecifications
              {...sectionTechnicalSpecification}
              shopifyData={shopifyData}
              productId={productId}
              siteConfiguration={siteConfiguration}
              isRenderedInModal={true}
              handleModalClose={() => setUrlFragment('')}
            />
          </TechnicalSpecificationsWrap>
        </Modal>
      )}
    </SectionWrapperNoTopMargin>
  )
}
